// farmer general info store
export const farmerInfoStore = '/common-config/farmer-information/farmer-info/store'
export const farmerInfoupdate = '/common-config/farmer-information/farmer-info/update'
export const farmerListData = '/common-config/farmer-information/list'
export const farmerAllDetailsData = '/common-config/farmer-information/show'
export const farmerInfoToggleStatus = '/common-config/farmer-information/toggle-status'

// Report Header Api
export const reportHeadingList = '/common-config/report-heading/list'
export const reportHeadingStore = '/common-config/report-heading/store'
export const reportHeadingUpdate = '/common-config/report-heading/update'
export const reportToggleStatus = '/common-config/report-heading/toggle-status'
export const reportHeaderDestroy = '/common-config/report-heading/destroy'
