<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
              <b-overlay :show="loading">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('common_config.farmer_information') }}</h4>
                    </template>
                    <template v-slot:body>
                        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                            <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                 <template>
                                    <div style="font-size:12px; background-color: #337982; padding:6px">
                                        <h5 class="text-white text-center"> {{ $t('admission_form.gen_inf') }}</h5>
                                    </div>
                                </template>
                                 <b-row class="mt-4">
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Name (En)" vid="name" rules="required">
                                        <b-form-group class="row" label-cols-sm="4" label-for="name" slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                            {{$t('common_config.farmer_name_en')}} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                          type="text"
                                          id="name"
                                          v-model="farmerInfo.name"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                        <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                                            <b-form-group class="row" label-cols-sm="4" label-for="name_bn" slot-scope="{ valid, errors }">
                                              <template v-slot:label>
                                                {{$t('common_config.farmer_name_bn')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-input
                                              type="text"
                                              id="pay_grade"
                                              v-model="farmerInfo.name_bn"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                       <ValidationProvider name="Father Name (En)" vid="father_name" rules="required">
                                          <b-form-group class="row" label-cols-sm="4" label-for="father_name" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                              {{$t('common_config.farmer_father_name_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            type="text"
                                            id="pay_grade"
                                            v-model="farmerInfo.father_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                        <ValidationProvider name="Father Name (Bn)" vid="father_name_bn" rules="required">
                                          <b-form-group class="row" label-cols-sm="4" label-for="father_name_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                              {{$t('common_config.farmer_father_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            v-model="farmerInfo.father_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                     <b-col lg="4" md="4" sm="12" xs="12">
                                        <ValidationProvider name="Mother Name (En)" vid="mother_name_en" rules="required">
                                          <b-form-group class="row" label-cols-sm="4" label-for="mother_name_en" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                              {{$t('admission_form.mother_name_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            type="text"
                                            id="mother_name_en"
                                            v-model="farmerInfo.mother_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                        <ValidationProvider name="Mother Name (Bn)" vid="mother_name_bn" rules="required">
                                          <b-form-group class="row" label-cols-sm="4" label-for="mother_name_bn" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                              {{$t('admission_form.mother_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            type="text"
                                            id="pay_grade"
                                            v-model="farmerInfo.mother_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                     <b-col lg="4" md="4" sm="12" xs="12">
                                        <ValidationProvider name="Date of Birth" vid="date_of_birth" rules="required">
                                          <b-form-group class="row" label-cols-sm="4" label-for="date_of_birth" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                              {{ $t('externalUserIrrigation.date_of_birth') }} <span class="text-danger">*</span>
                                            </template>
                                              <b-form-input
                                                class="date-picker"
                                                v-model="farmerInfo.date_of_birth"
                                                :placeholder="$t('globalTrans.select_date')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                     <b-col lg="4" md="4" sm="12" xs="12">
                                        <ValidationProvider name="Gender" vid="gender_id" rules="required|min_value:1">
                                          <b-form-group class="row" label-cols-sm="4" label-for="gender_id" slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                              {{$t('common_config.farmer_gender')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="farmerInfo.gender_id"
                                                :options="genderList"
                                                id="gender_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                      <b-col lg="4" md="4" sm="12" xs="12">
                                       <ValidationProvider name="Blood Group" vid="blood_group_id">
                                            <b-form-group class="row" label-cols-sm="4" label-for="blood_group_id" slot-scope="{ valid, errors }">
                                              <template v-slot:label>
                                                {{$t('common_config.farmer_blood_group')}}
                                              </template>
                                              <b-form-select
                                                  plain
                                                  v-model="farmerInfo.blood_group_id"
                                                  :options="bloodGroupList"
                                                  id="blood_group_id"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  >
                                                  <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                  </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                         <ValidationProvider name="Education Level" vid="education_level_id">
                                            <b-form-group class="row" label-cols-sm="4" label-for="education_level_id" slot-scope="{ valid, errors }">
                                              <template v-slot:label>
                                                {{$t('common_config.farmer_education_lebel')}}
                                              </template>
                                              <b-form-select
                                                  plain
                                                  v-model="farmerInfo.education_level_id"
                                                  :options="educationLevelList"
                                                  id="education_level_id"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  >
                                                  <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                  </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="NID" vid="nid_no" rules="required">
                                        <b-form-group class="row" label-cols-sm="4" label-for="nid" slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                            {{$t('admission_form.nid_no')}} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                            type="number"
                                            id="nid_no"
                                            v-model="farmerInfo.nid_no"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|digits:11">
                                        <b-form-group class="row" label-cols-sm="4" label-for="mobile_no" slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                            {{$t('admission_form.mobile_no')}} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                            type="text"
                                            id="mobile_no"
                                            v-model="farmerInfo.mobile_no"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                     <b-col lg="4" md="4" sm="12" xs="12">
                                       <ValidationProvider name="Photo" vid="attachment">
                                        <b-form-group class="row" label-cols-sm="4" label-for="photo" slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                            {{ $t('admission_form.photo') }}
                                          </template>
                                          <b-form-file
                                            id="attachment"
                                            v-model="farmerInfo.attachment"
                                            :placeholder="$t('common_config.file_chosen')"
                                            @change="onFileChange"
                                            accept="image/png, image/gif, image/jpeg"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                          </b-form-file>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                 </b-row>
                                  <template>
                                    <div style="font-size:12px; background-color: #337982; padding:6px">
                                        <h5 class="text-white text-center"> {{ $t('common_config.other_info') }}</h5>
                                    </div>
                                </template>
                                 <b-row class="mt-4">
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Card Number" vid="card_number">
                                        <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="card_number"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('common_config.card_number')}}
                                        </template>
                                        <b-form-input
                                          id="card_number"
                                          v-model="farmerInfo.card_number"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                        <ValidationProvider name="Family Number" vid="family_number">
                                          <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="family_number"
                                            slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                            {{ $t('common_config.family_number')}}
                                          </template>
                                          <b-form-input
                                            id="family_number"
                                            v-model="farmerInfo.family_number"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                     <b-col lg="4" md="4" sm="12" xs="12">
                                        <ValidationProvider name="Association Name (En)" vid="association_name">
                                          <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="association_name"
                                            slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                            {{ $t('common_config.association_name_en')}}
                                          </template>
                                          <b-form-input
                                            id="association_name"
                                            v-model="farmerInfo.association_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                        <ValidationProvider name="Association Name (Bn)" vid="association_name_bn">
                                          <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="association_name_bn"
                                            slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                            {{ $t('common_config.association_name_bn')}}
                                          </template>
                                          <b-form-input
                                            id="association_name_bn"
                                            v-model="farmerInfo.association_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                       <ValidationProvider name="Income Source Without Agriculture (En)" vid="income_source">
                                          <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="income_source"
                                            slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                            {{ $t('common_config.income_source_en')}}
                                          </template>
                                          <b-form-input
                                            id="income_source"
                                            v-model="farmerInfo.income_source"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Income Source Without Agriculture (bn)" vid="income_source_bn">
                                        <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="income_source_bn"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('common_config.income_source_bn')}}
                                        </template>
                                        <b-form-input
                                          id="income_source_bn"
                                          v-model="farmerInfo.income_source_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                 </b-row>
                                 <template>
                                    <div style="font-size:12px; background-color: #337982; padding:6px">
                                        <h5 class="text-white text-center"> {{ $t('common_config.land_info') }}</h5>
                                    </div>
                                </template>
                                 <b-row class="mt-4">
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                        <ValidationProvider name="Own Land" vid="own_land" rules="required|min_value:1">
                                          <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="own_land"
                                            slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                              {{$t('common_config.own_land')}} <span class="text-danger">*</span>
                                            </template>
                                          <b-form-input
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            id="own_land"
                                            v-model="farmerInfo.own_land"
                                            @input="totalSum"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Lease land" vid="lease_land">
                                        <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="lease_land"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('common_config.lease_land')}}
                                          </template>
                                        <b-form-input
                                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                          id="lease_land"
                                          v-model="farmerInfo.lease_land"
                                          @input="totalSum"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Barga Land" vid="barga_land">
                                        <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="barga_land"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('common_config.barga_land')}}
                                          </template>
                                        <b-form-input
                                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                          id="lease_land"
                                          v-model="farmerInfo.barga_land"
                                          @input="totalSum"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Total Land" vid="total_land">
                                      <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="total_land"
                                        slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                          {{$t('common_config.amount_of_land')}}
                                        </template>
                                      <b-form-input
                                        readonly
                                        id="total_land"
                                        v-model="farmerInfo.total_land"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                    </b-col>
                                 </b-row>
                                 <template>
                                    <div style="font-size:12px; background-color: #337982; padding:6px">
                                        <h5 class="text-white text-center"> {{ $t('common_config.crop_info') }}</h5>
                                    </div>
                                 </template>
                                 <b-row>
                                <b-col>
                                <b-row v-for="(detail,index) in farmerInfo.crop_info" :key="index" class="mt-4">
                                <b-col xl="6" lg="4" sm="8">
                                   <ValidationProvider name="Crop Name" vid="crop_id" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="crop_id"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                          {{ $t('common_config.crop_name') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="detail.crop_id"
                                          :options="cropInfoList"
                                          id="crop_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xl="2" lg="12" sm="4" class="mt-2">
                                    <b-button v-show="index == farmerInfo.crop_info.length-1" variant=" iq-bg-success" size="sm" @click="addCrop()"><i class="ri-add-line m-0"></i>{{$t('common_config.add_more')}}</b-button>
                                    <b-button v-show="index || ( !index && farmerInfo.crop_info.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                </b-col>
                                </b-row>
                                </b-col>
                                </b-row>
                                <template>
                                  <div style="font-size:12px; background-color: #337982; padding:6px">
                                      <h5 class="text-white text-center"> {{ $t('admission_form.add_info') }}</h5>
                                  </div>
                                 </template>
                                 <b-row>
                                  <b-col lg="6" sm="12" class="mt-3">
                                    <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="area_type_id "
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="farmerInfo.area_type_id"
                                            id="area_type_id"
                                            :options="areaTypeList"
                                            @change="getAreaTypeData(farmerInfo.area_type_id)"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                      <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="division_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.division_id"
                                              :options="divisionList"
                                              id="division_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                      <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="district_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.district_id"
                                              :options="districtList"
                                              id="district_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="PauroshobaItemShow || UnionItemShow">
                                      <ValidationProvider name="Upazila" vid="upazila_id">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="upazila_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.upazilla_id"
                                              :options="upazilaList"
                                              id="upazila_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="UnionItemShow">
                                      <ValidationProvider name="Union" vid="union_id">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="union_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.union_id"
                                              :options="unionList"
                                              id="union_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="CityCorpItemShow">
                                      <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="city_corporation_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.city_corporation_id"
                                              :options="cityCorporationList"
                                              id="city_corporation_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="PauroshobaItemShow">
                                      <ValidationProvider name="Pauroshoba" vid="pauroshoba_id">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="pauroshoba_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.pauroshoba_id"
                                              :options="pauroshobaList"
                                              id="pauroshoba_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                      <ValidationProvider name="Ward" vid="ward_id" >
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="ward_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  {{ $t('org_pro.ward') }}
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.ward_id"
                                              :options="wardList"
                                              id="ward_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                      <ValidationProvider name="Village" vid="village_en">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="village_en"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                {{ $t('common_config.village') }} {{ ' ' + $t('globalTrans.enn') }}
                                              </template>
                                              <b-form-input
                                                type="text"
                                                id="village_en"
                                                v-model="farmerInfo.village_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                      <ValidationProvider name="Village" vid="village_bn">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="village_bn"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                {{ $t('common_config.village') }} {{ ' ' + $t('globalTrans.bnn') }}
                                              </template>
                                              <b-form-input
                                                type="text"
                                                id="village_bn"
                                                v-model="farmerInfo.village_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                      <ValidationProvider name="Block" vid="block_en">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="block_en"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  {{ $t('common_config.block') }} {{ ' ' + $t('globalTrans.enn') }}
                                              </template>
                                              <b-form-input
                                                type="text"
                                                id="block_en"
                                                v-model="farmerInfo.block_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                  <b-col lg="6" sm="12" v-show="ItemShow">
                                      <ValidationProvider name="Block" vid="block_bn">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="block_bn"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  {{ $t('common_config.block') }} {{ ' ' + $t('globalTrans.bnn') }}
                                              </template>
                                              <b-form-input
                                                type="text"
                                                id="block_bn"
                                                v-model="farmerInfo.block_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <template>
                                  <div style="font-size:12px; background-color: #337982; padding:6px">
                                      <h5 class="text-white text-center"> {{ $t('educational_management.permanent_address') }}</h5>
                                  </div>
                                 </template>
                                <b-row>
                                    <b-col  lg="6"  sm="12">
                                        <ValidationProvider>
                                            <b-form-group
                                                class="row"
                                                :label="$t('educational_management.present_business_address')"
                                                label-cols-sm="4"
                                                label-for="present_address"
                                                slot-scope="{ errors }"
                                            >
                                                <div>
                                                    <b-form-checkbox
                                                        class="mt-2"
                                                        id="same_as_present_address"
                                                        v-model="same_as_present_address"
                                                        :value="true"
                                                        :unchecked-value="false"
                                                    >
                                                        {{$t('admission_form.same')}}
                                                    </b-form-checkbox>
                                                </div>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="Area Type" vid="per_area_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_area_type_id "
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                    {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    :disabled="same_as_present_address === 1"
                                                    v-model="farmerInfo.per_area_type_id"
                                                    id="per_area_type_id"
                                                    :options="areaTypeList"
                                                    @change="getAreaTypePerData(farmerInfo.per_area_type_id)"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="6" sm="12" v-show="ItemShowPer">
                                        <ValidationProvider name="Division" vid="per_division_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_division_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_division_id"
                                                :options="divisionPerList"
                                                id="per_division_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="ItemShowPer">
                                        <ValidationProvider name="District" vid="per_district_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_district_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_district_id"
                                                :options="districtPerList"
                                                id="per_district_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="PauroshobaItemPerShow || UnionItemPerShow">
                                        <ValidationProvider name="Upazila" vid="per_upazila_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_upazila_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_upazilla_id"
                                                :options="upazilaPerList"
                                                id="per_upazila_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="UnionItemPerShow">
                                        <ValidationProvider name="Union" vid="per_union_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_union_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_union_id"
                                                :options="unionPerList"
                                                id="per_union_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="CityCorpItemPerShow">
                                        <ValidationProvider name="City Corporation" vid="per_city_corporation_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_city_corporation_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_city_corporation_id"
                                                :options="cityCorporationPerList"
                                                id="per_city_corporation_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="PauroshobaItemPerShow">
                                        <ValidationProvider name="Pauroshoba" vid="per_pauroshoba_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_pauroshoba_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_pauroshoba_id"
                                                :options="pauroshobaPerList"
                                                id="per_pauroshoba_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="ItemShowPer">
                                        <ValidationProvider name="Ward" vid="per_ward_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_ward_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.ward') }}
                                                </template>
                                                <b-form-select
                                                plain
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_ward_id"
                                                :options="wardPerList"
                                                id="ward_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <!-- <b-col lg="6" sm="12" v-show="ItemShowPer">
                                        <ValidationProvider name="Village" vid="per_village_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_village_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('common_config.village') }}
                                                </template>
                                                <b-form-select
                                                plain
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_village_id"
                                                :options="perVillageList"
                                                id="per_village_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="ItemShowPer">
                                        <ValidationProvider name="Block" vid="per_block_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_block_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('common_config.block') }}
                                                </template>
                                                <b-form-select
                                                plain
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_block_id"
                                                :options="blockPerList"
                                                id="per_block_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col> -->
                                    <b-col lg="6" sm="12" v-show="ItemShowPer">
                                      <ValidationProvider name="Village" vid="per_village_en">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="per_village_en"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                {{ $t('common_config.village') }} {{ ' ' + $t('globalTrans.enn') }}
                                              </template>
                                              <b-form-input
                                                type="text"
                                                id="per_village_en"
                                                :disabled="same_as_present_address === 1"
                                                v-model="farmerInfo.per_village_en"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="ItemShowPer">
                                        <ValidationProvider name="Village" vid="per_village_bn">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_village_bn"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                  {{ $t('common_config.village') }} {{ ' ' + $t('globalTrans.bnn') }}
                                                </template>
                                                <b-form-input
                                                  type="text"
                                                  id="per_village_bn"
                                                  :disabled="same_as_present_address === 1"
                                                  v-model="farmerInfo.per_village_bn"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="ItemShowPer">
                                        <ValidationProvider name="Block" vid="per_block_en">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_block_en"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('common_config.block') }} {{ ' ' + $t('globalTrans.enn') }}
                                                </template>
                                                <b-form-input
                                                  type="text"
                                                  id="per_block_en"
                                                  :disabled="same_as_present_address === 1"
                                                  v-model="farmerInfo.per_block_en"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-show="ItemShowPer">
                                        <ValidationProvider name="Block" vid="per_block_bn">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="per_block_bn"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('common_config.block') }} {{ ' ' + $t('globalTrans.bnn') }}
                                                </template>
                                                <b-form-input
                                                  type="text"
                                                  id="per_block_bn"
                                                  :disabled="same_as_present_address === 1"
                                                  v-model="farmerInfo.per_block_bn"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <template>
                                  <div style="font-size:12px; background-color: #337982; padding:6px">
                                      <h5 class="text-white text-center"> {{ $t('common_config.Office_mapping') }}</h5>
                                  </div>
                                </template>
                                <b-row>
                                    <b-col lg="12" sm="6">
                                      <ValidationProvider name="Organization list" vid="org_id" rules="required">
                                          <b-form-group
                                              label-cols-sm="2"
                                              :label="$t('globalTrans.organization')+' *'"
                                              label-for="organization"
                                              slot-scope="{ valid, errors }"
                                            >
                                              <b-form-checkbox-group
                                                style="margin-top: 5px;"
                                                v-model="orgObj.org_id"
                                                :options="orgList"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-checkbox-group>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-row v-show="inputFieldShow">
                                    <b-col xl="6" lg="4" sm="8">
                                      <ValidationProvider name="Mill Name" vid="mill_name_id" :rules="bsriValidationRules">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="mill_name_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{ $t('bsri_config.mill_name') }} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.mill_info_id"
                                              :options="millInfoList"
                                              id="mill_name_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="4" sm="8">
                                      <ValidationProvider name="Subzone office name" vid="subzone_office_id" :rules="bsriValidationRules">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="subzone_office_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{ $t('bsri_config.subzone_office_name') }} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.subzone_office_id"
                                              :options="subzoneOfficeList"
                                              id="subzone_office_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="4" sm="8">
                                      <ValidationProvider name="Center name" vid="center_id" :rules="bsriValidationRules">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="center_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{ $t('bsri_config.center_name') }} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.center_id"
                                              :options="centerList"
                                              id="center_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="4" sm="8">
                                      <ValidationProvider name="Unit Name" vid="unit_id" :rules="bsriValidationRules">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="unit_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{ $t('bsri_config.unit_no') }} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.unit_id"
                                              :options="unitList"
                                              id="unit_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <template>
                                    <div style="font-size:12px; background-color: #337982; padding:6px">
                                        <h5 class="text-white text-center"> {{ $t('common_config.account_info') }}</h5>
                                    </div>
                                </template>
                                <b-row class="mt-4">
                                  <b-col lg="8" md="8" sm="12" xs="12" offset="2">
                                      <ValidationProvider name="Account Type" vid='ac_category_id'>
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="ac_category_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                             <template v-slot:label>
                                              {{ $t('common_config.account_info') }} :
                                              </template>
                                            <b-form-radio-group
                                              v-model="farmerInfo.ac_category_id"
                                              :options="accountTypeList"
                                              class="mb-3"
                                              value-field="value"
                                              text-field="text"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-radio-group>
                                            <div class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                </b-row>
                                <b-row v-if="farmerInfo.ac_category_id === 1">
                                  <b-col lg="4" md="4" sm="12" xs="12">
                                        <b-form-checkbox
                                            class="mt-2"
                                            id="same_as_mobile_number"
                                            v-model="same_as_mobile_number"
                                            :value="true"
                                            :unchecked-value="false"
                                        >
                                            {{$t('common_config.select_checkbox')}}
                                        </b-form-checkbox>
                                    </b-col>
                                   <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Wallet No" vid="wallet_no">
                                        <b-form-group class="row" label-cols-sm="4" label-for="wallet_no" slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                            {{$t('common_config.far_wallet_no')}}
                                          </template>
                                          <b-form-input
                                            id="wallet_no"
                                            v-model="farmerInfo.wallet_no"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                   <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                      <ValidationProvider name="A/C Bank" vid="ac_type_id" rules="min_value:1">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="ac_type_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{ $t('common_config.account_type') }}
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.ac_type_id"
                                              :options="accountBankList"
                                              id="ac_type_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                   </b-col>
                                </b-row>
                                <b-row v-if="farmerInfo.ac_category_id === 2">
                                   <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Account No" vid="account_no">
                                        <b-form-group class="row" label-cols-sm="4" label-for="mobile_no" slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                            {{$t('common_config.account_no')}}
                                          </template>
                                          <b-form-input
                                            type="text"
                                            id="account_no"
                                            v-model="farmerInfo.account_no"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                   </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                      <ValidationProvider name="bank" vid="bank_id" rules="min_value:1">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="bank_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{ $t('bsri_demandDIS.bank') }}
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="farmerInfo.bank_id"
                                              :options="bankList"
                                              id="bank_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="branch" vid="branch_id" rules="min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="branch_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('bsri_demandDIS.branch') }}
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="farmerInfo.branch_id"
                                                :options="branchList"
                                                id="bank_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                   <b-col lg="4" md="4" sm="12" xs="12">
                                      <ValidationProvider name="Routing No" vid="routing_no" rules="required">
                                        <b-form-group class="row" label-cols-sm="4" label-for="routing_no" slot-scope="{ valid, errors }">
                                          <template v-slot:label>
                                            {{$t('common_config.routing_no')}}
                                          </template>
                                          <b-form-input
                                            type="text"
                                            id="routing_no"
                                            v-model="farmerInfo.routing_no"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                   </b-col>
                                </b-row>
                                <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col text-right">
                                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                        &nbsp;
                                        <!-- <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button> -->
                                        <b-button variant="danger" class="mr-1">
                                            <router-link :to="{ path: '/incentive-grant-service/common-configuration/farmer-information'}" size="sm" style="color:#fff !important">
                                               {{ $t('globalTrans.cancel') }}
                                            </router-link>
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </template>
                </iq-card>
              </b-overlay>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { farmerInfoStore, farmerInfoupdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            // loading: false,
            showIdType: 0,
            ItemShow: false,
            inputFieldShow: false,
            ItemShowPer: false,
            CityCorpItemShow: false,
            CityCorpItemPerShow: false,
            PauroshobaItemShow: false,
            PauroshobaItemPerShow: false,
            UnionItemShow: false,
            UnionItemPerShow: false,
            same_as_present_address: false,
            same_as_mobile_number: false,
            districtList: [],
            upazilaList: [],
            unionList: [],
            cityCorporationList: [],
            pauroshobaList: [],
            wardList: [],
            blockList: [],
            blockPerList: [],
            districtPerList: [],
            upazilaPerList: [],
            unionPerList: [],
            cityCorporationPerList: [],
            pauroshobaPerList: [],
            wardPerList: [],
            // millInfoList: [],
            subzoneOfficeList: [],
            accountBankList: [],
            bankList: [],
            branchList: [],
            centerList: [],
            unitList: [],
            farmerInfo: {
              id: '',
              org_id: 2,
              name: '',
              name_bn: '',
              father_name: '',
              father_name_bn: '',
              mother_name: '',
              mother_name_bn: '',
              date_of_birth: '',
              gender_id: 0,
              blood_group_id: 0,
              education_level_id: 0,
              mobile_no: '',
              wallet_no: '',
              nid_no: '',
              attachment: null,
              card_number: '',
              family_number: '',
              association_name: '',
              association_name_bn: '',
              income_source: '',
              income_source_bn: '',
              own_land: 0,
              lease_land: 0,
              barga_land: 0,
              total_land: 0,
              ac_category_id: 1,
              account_no: '',
              routing_no: '',
              ac_type_id: 0,
              bank_id: 0,
              branch_id: 0,
              crop_info: [
                  {
                      crop_id: 0
                  }
                ],
              area_type_id: 0,
              city_corporation_id: 0,
              pauroshoba_id: 0,
              country_id: 1,
              division_id: 0,
              district_id: 0,
              upazilla_id: '0',
              union_id: '0',
              ward_id: '0',
              village_en: '',
              block_en: '',
              village_bn: '',
              block_bn: '',
              is_same: 2,
              per_area_type_id: '0',
              per_city_corporation_id: '0',
              per_pauroshoba_id: '0',
              per_country_id: 1,
              per_division_id: '0',
              per_district_id: '0',
              per_upazilla_id: '0',
              per_union_id: '0',
              per_ward_id: '0',
              per_village_en: '',
              per_village_bn: '',
              per_block_en: '',
              per_block_bn: '',
              mill_type_id: '0',
              mill_info_id: '0',
              subzone_office_id: '0',
              center_id: '0',
              unit_id: '0'
            },
            genderList: [
              { value: 1, text: this.$t('common_config.male') },
              { value: 2, text: this.$t('common_config.female') },
              { value: 3, text: this.$t('common_config.other') }
            ],
            accountTypeList: [
              { value: 1, text: this.$t('common_config.mobile_banking') },
              { value: 2, text: this.$t('common_config.bank') }
            ],
            orgObj: {
              org_id: []
            },
            bsriValidationRules: ''
        }
    },
    created () {
      if (this.$route.query.id) {
        const tmp = this.getFarmerInfoData()
        this.farmerInfo = Object.assign(tmp, { org_id: parseInt(tmp.org_id) })
        this.orgObj.org_id = [tmp.org_id]
        this.getAreaTypeData(this.farmerInfo.area_type_id)
        this.getAreaTypePerData(this.farmerInfo.per_area_type_id)
        if (this.farmerInfo.is_same) {
          this.same_as_present_address = true
        } else {
          this.same_as_present_address = false
        }
      }
    },
    mounted () {
      flatpickr('.date-picker', {})
      core.index()
    },
    watch: {
        'farmerInfo.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'farmerInfo.per_division_id': function (newVal, oldVal) {
            this.districtPerList = this.getDistrictPerList(newVal)
        },
        'farmerInfo.district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'farmerInfo.per_district_id': function (newVal, oldVal) {
            this.cityCorporationPerList = this.getCityCorporationPerList(newVal)
            this.upazilaPerList = this.getUpazilaPerList(newVal)
        },
        'farmerInfo.upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.pauroshobaList = this.getPauroshobaList(newVal)
        },
        'farmerInfo.per_upazilla_id': function (newVal, oldVal) {
            this.unionPerList = this.getUnionPerList(newVal)
            this.pauroshobaPerList = this.getPauroshobaPerList(newVal)
        },
        'farmerInfo.city_corporation_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByCityCorportaion(newVal)
        },
        'farmerInfo.per_city_corporation_id': function (newVal, oldVal) {
            this.wardPerList = this.getWardListByCityCorportaionPer(newVal)
        },
        'farmerInfo.union_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByUnion(newVal)
        },
        'farmerInfo.per_union_id': function (newVal, oldVal) {
            this.wardPerList = this.getWardPerListByUnion(newVal)
        },
        'farmerInfo.mill_info_id': function (newVal, oldVal) {
            this.subzoneOfficeList = this.getSubzoneList(newVal)
        },
        'farmerInfo.subzone_office_id': function (newVal, oldVal) {
            this.centerList = this.getCenterList(newVal)
        },
        'farmerInfo.center_id': function (newVal, oldVal) {
            this.unitList = this.getUnitList(newVal)
        },
        same_as_present_address: function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.farmerInfo.per_area_type_id = this.farmerInfo.area_type_id
            this.farmerInfo.per_city_corporation_id = this.farmerInfo.city_corporation_id
            this.farmerInfo.per_pauroshoba_id = this.farmerInfo.pauroshoba_id
            this.farmerInfo.per_division_id = this.farmerInfo.division_id
            this.farmerInfo.per_district_id = this.farmerInfo.district_id
            this.farmerInfo.per_upazilla_id = this.farmerInfo.upazilla_id
            this.farmerInfo.per_union_id = this.farmerInfo.union_id
            this.farmerInfo.per_ward_id = this.farmerInfo.ward_id
            this.farmerInfo.per_village_en = this.farmerInfo.village_en
            this.farmerInfo.per_village_bn = this.farmerInfo.village_bn
            this.farmerInfo.per_block_en = this.farmerInfo.block_en
            this.farmerInfo.per_block_bn = this.farmerInfo.block_bn
            this.farmerInfo.is_same = newVal
            this.ItemShowPer = true
            if (this.farmerInfo.per_area_type_id === 1) {
              this.CityCorpItemPerShow = true
              this.PauroshobaItemPerShow = false
              this.UnionItemPerShow = false
            } else if (this.farmerInfo.per_area_type_id === 2) {
              this.CityCorpItemPerShow = false
              this.PauroshobaItemPerShow = true
              this.UnionItemPerShow = false
            } else if (this.farmerInfo.per_area_type_id === 3) {
              this.CityCorpItemPerShow = false
              this.PauroshobaItemPerShow = false
              this.UnionItemPerShow = true
            }
          }
        },
        'orgObj.org_id': function (newVal, oldVal) {
          if (newVal.length >= 2) {
            newVal.forEach(element => {
              if (oldVal.indexOf(element) === -1) {
                this.orgObj.org_id = [element]
              }
            })
            return
          }
          newVal.map((obj, key) => {
              if (obj === 8) {
                this.inputFieldShow = true
              }
              if (obj !== 8) {
                this.inputFieldShow = false
              }
              this.farmerInfo.org_id = this.orgObj.org_id.join()
              if (this.farmerInfo.org_id.includes(8)) {
                this.bsriValidationRules = 'required|min_value:1'
              } else {
                this.bsriValidationRules = ''
              }
          })
          if (typeof newVal[0] === 'undefined') {
            this.bankList = []
            this.accountBankList = []
          }
        },
        same_as_mobile_number: function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.farmerInfo.wallet_no = this.farmerInfo.mobile_no
          }
        },
        'farmerInfo.org_id': function (newVal, oldVal) {
          if (oldVal !== newVal) {
            this.accountBankList = this.getAccountBankList(newVal)
            this.bankList = this.getBankList(newVal)
          }
        },
        'farmerInfo.bank_id': function (newVal, oldVal) {
          if (oldVal !== newVal) {
            this.branchList = this.getBranchList(newVal)
          }
        }
    },
    computed: {
      educationLevelList: function () {
        return this.$store.state.incentiveGrant.commonObj.educationLevelList.filter(item => item.status === 1)
      },
      bloodGroupList: function () {
          const list = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'O-' : 'ও−' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'A-' : 'এ−' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
          { value: 5, text: this.$i18n.locale === 'en' ? 'B-' : 'বি−' },
          { value: 6, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
          { value: 7, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি−' },
          { value: 8, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' }
          ]
          return list
      },
      divisionList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      },
      divisionPerList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      },
      cropInfoList: function () {
        return this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
      },
      areaTypeList: function () {
        const list = [
          { value: 1, text: this.$i18n.locale === 'bn' ? 'সিটি কর্পোরেশন' : 'City Corpoation' },
          { value: 2, text: this.$i18n.locale === 'bn' ? 'পৌরসভা' : 'Pauroshoba' },
          { value: 3, text: this.$i18n.locale === 'bn' ? 'ইউনিয়ন' : 'Union' }
        ]
        return list
      },
      orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.abbreviation_bn }
              } else {
                  return { value: obj.value, text: obj.abbreviation }
              }
          })
      },
      millTypeList: function () {
          const objectData = this.$store.state.incentiveGrant.commonObj.millTypeList.filter(item => item.status === 1)
          return objectData.map(obj => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      millInfoList: function () {
        return this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      },
      loading () {
        return this.$store.state.commonObj.loading
      }
    },
    methods: {
       getAccountBankList: function (OrgId) {
          const accountBankList = this.$store.state.commonObj.bankObj.bankList.filter(item => item.status === 0 && item.org_id === parseInt(OrgId))
          return accountBankList.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
              return { value: item.value, text: item.text_en }
            }
          })
        },
        getBankList: function (OrgId) {
           const bankList = this.$store.state.commonObj.bankObj.bankList.filter(item => item.status === 0 && item.org_id === parseInt(OrgId))
            return bankList.map(item => {
              if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
              } else {
                return { value: item.value, text: item.text_en }
              }
          })
        },
        getBranchList: function (bankId) {
          const branchList = this.$store.state.commonObj.bankObj.branchList.filter(item => item.status === 0 && item.bank_id === bankId)
          return branchList.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
              return { value: item.value, text: item.text_en }
            }
          })
        },
        getMillInfoList (id) {
          const dataObject = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1 && item.mill_type_id === id)
          return dataObject.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
              return { value: item.value, text: item.text_en }
            }
          })
        },
        getSubzoneList (id) {
          const dataObject = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.filter(item => item.status === 1 && item.mill_info_id === id)
          return dataObject.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
              return { value: item.value, text: item.text_en }
            }
          })
        },
        getCenterList (id) {
          const dataObject = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item.status === 1 && item.subzone_office_id === id)
          return dataObject.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
              return { value: item.value, text: item.text_en }
            }
          })
        },
        getUnitList (id) {
          const dataObject = this.$store.state.incentiveGrant.commonObj.unitList.filter(item => item.status === 1 && item.center_id === id)
          return dataObject.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
              return { value: item.value, text: item.text_en }
            }
          })
        },
        totalSum () {
          const ownLand = this.farmerInfo.own_land ? parseFloat(this.farmerInfo.own_land) : 0
          const leaseLand = this.farmerInfo.lease_land ? parseFloat(this.farmerInfo.lease_land) : 0
          const bargaLand = this.farmerInfo.barga_land ? parseFloat(this.farmerInfo.barga_land) : 0
          this.farmerInfo.total_land = parseFloat(ownLand + leaseLand + bargaLand)
        },
        getAreaTypeData (typeId) {
          this.ItemShow = true
          if (typeId === 1) {
            this.CityCorpItemShow = true
            this.PauroshobaItemShow = false
            this.UnionItemShow = false
          } else if (typeId === 2) {
            this.CityCorpItemShow = false
            this.PauroshobaItemShow = true
            this.UnionItemShow = false
          } else if (typeId === 3) {
            this.CityCorpItemShow = false
            this.PauroshobaItemShow = false
            this.UnionItemShow = true
          }
        },
        getAreaTypePerData (typeId2) {
          this.ItemShowPer = true
          if (typeId2 === 1) {
            this.CityCorpItemPerShow = true
            this.PauroshobaItemPerShow = false
            this.UnionItemPerShow = false
          } else if (typeId2 === 2) {
            this.CityCorpItemPerShow = false
            this.PauroshobaItemPerShow = true
            this.UnionItemPerShow = false
          } else if (typeId2 === 3) {
            this.CityCorpItemPerShow = false
            this.PauroshobaItemPerShow = false
            this.UnionItemPerShow = true
          }
        },
        getDistrictList (divisionId = null) {
          const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
              return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getDistrictPerList (divisionPerId = null) {
          const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
          if (divisionPerId) {
            return districtList.filter(district => district.division_id === divisionPerId)
          }
          return districtList
        },
        getUpazilaList (districtId = null) {
          const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
          if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
          }
          return upazilaList
        },
        getUpazilaPerList (districtId = null) {
          const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
              return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnionList (upazilaId = null) {
          const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazilaId) {
              return unionList.filter(union => union.upazilla_id === upazilaId)
            }
            return unionList
        },
        getUnionPerList (upazilaId = null) {
          const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
          if (upazilaId) {
            return unionList.filter(union => union.upazilla_id === upazilaId)
          }
          return unionList
        },
        getCityCorporationList (districtId) {
          const objectData = this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
              } else {
                return { value: obj.value, text: obj.text_en }
              }
            })
        },
        getCityCorporationPerList (districtId) {
          const objectData = this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
                } else {
                  return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getPauroshobaList (upazillaId = null) {
          const objectData = this.$store.state.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
            if (upazillaId) {
              return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
                } else {
                  return { value: obj.value, text: obj.text_en }
                }
              })
            }
        },
        getPauroshobaPerList (upazillaId = null) {
          const objectData = this.$store.state.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
          if (upazillaId) {
            return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
              } else {
                return { value: obj.value, text: obj.text_en }
              }
            })
          }
        },
        getWardListByCityCorportaion (cityCorpId) {
          const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
              return wardObjectList.map((obj, key) => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.value, text: obj.text_bn }
                  } else {
                      return { value: obj.value, text: obj.text }
                  }
              })
        },
        getWardListByCityCorportaionPer (cityCorpId) {
          const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
              return wardObjectList.map((obj, key) => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.value, text: obj.text_bn }
                  } else {
                      return { value: obj.value, text: obj.text }
                  }
              })
        },
        getWardListByPauroshoba (pauroshobaId) {
            const objectData = this.$store.state.commonObj.wardList
              const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
                return wardObjectList.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text }
                    }
                })
        },
        getWardListByUnion (unionId) {
          const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
              return wardObjectList.map((obj, key) => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.value, text: obj.text_bn }
                  } else {
                      return { value: obj.value, text: obj.text }
                  }
              })
        },
        getWardPerListByUnion (unionPerId) {
          const objectData = this.$store.state.commonObj.wardList
          const wardObjectList = objectData.filter(item => item.union_id === unionPerId && item.status === 0)
          return wardObjectList.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text }
            }
          })
        },
        getFarmerInfoData () {
          const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.$route.query.id))
          return JSON.parse(JSON.stringify(tmpData))
        },
        onFileChange (e) {
          this.attachment = e.target.files[0]
        },
        async saveData () {
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadingState = { loading: false, listReload: false }
          const config = {
              headers: { 'content-type': 'multipart/form-data' }
          }
          /** if org other than bsri(id 8) then following data should be reset */
          if (this.farmerInfo.org_id.indexOf('8') === -1) {
            this.farmerInfo.mill_type_id = 0
            this.farmerInfo.mill_info_id = 0
            this.farmerInfo.subzone_office_id = 0
            this.farmerInfo.center_id = 0
            this.farmerInfo.unit_id = 0
          }
          var formData = new FormData()
            Object.keys(this.farmerInfo).map(key => {
              if (key === 'attachment') {
                formData.append(key, this.attachment)
              } else {
                formData.append(key, this.farmerInfo[key])
              }
            })
           formData.append('crop_info', JSON.stringify(this.farmerInfo.crop_info))
          if (this.farmerInfo.id) {
            formData.append('_method', 'PUT')
              result = await RestApi.postData(incentiveGrantServiceBaseUrl, `${farmerInfoupdate}/${this.$route.query.id}`, formData)
          } else {
              result = await RestApi.postData(incentiveGrantServiceBaseUrl, farmerInfoStore, formData, config)
          }

          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)

          if (result.success) {
              this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
              this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
              })
              this.$router.push({ path: '/incentive-grant-service/common-configuration/farmer-information' })
          } else {
            if (result.errors) {
              this.$refs.form.setErrors(result.errors)
              this.$toast.error({
                  title: 'Error',
                  message: result.message,
                  color: '#D6E09B'
              })
            } else {
              this.$toast.error({
                title: 'Error',
                message: result.message,
                color: '#D6E09B'
              })
            }
          }
        },
        addCrop () {
          const tampbpSetup = {
              crop_id: ''
          }
          const key1 = parseInt(this.farmerInfo.crop_info.length - 1)
          const item = this.farmerInfo.crop_info[key1]
          let isEmpty = true
          Object.keys(item).map(key => {
              if (item[key] === '' || item[key] === 0) {
                  isEmpty = false
              }
          })
          if (isEmpty === true) {
              this.farmerInfo.crop_info.push(tampbpSetup)
          }
        },
        remove (key) {
          this.farmerInfo.crop_info.splice(key, 1)
        }
    }
}
</script>
